import { BaseHttpService } from '@shared/abstractions/base-http.service';
import { Injectable } from '@angular/core';
import LessonDto, { RateLessonDto } from '@shared/services/lessons/dto/lesson.dto';
import { LessonsPagedQueryDto } from '@shared/services/lessons/dto/lessons-paged-query.dto';
import { Observable } from 'rxjs';
import BasePagedModel from '@shared/models/base-paged-model';
import { StudentQuizzesDataInterface } from '@pages/quizzes/quizzes-list/quizzes-list.component';
import { SkipLessonBodyDto, SkipLessonResponseDto } from '@shared/services/lessons/dto/skip-lesson.dto';
import { BookAdditionalLessonDto } from './dto/book-additional-lesson.dto';

@Injectable({
  providedIn: 'root',
})
export default class LessonsApiService extends BaseHttpService {
  constructor() {
    super('lessons');
  }

  public getById(id: string) {
    return this.get<LessonDto>(id);
  }

  public updateById(id: string, formData: FormData) {
    return this.put<void>(id, formData);
  }

  public getAllFuturePaged(query: LessonsPagedQueryDto) {
    return this.getPaged<LessonDto, LessonsPagedQueryDto>('future', query);
  }

  public getConductedLessons(query: LessonsPagedQueryDto): Observable<BasePagedModel<LessonDto>> {
    return this.getPaged<LessonDto, LessonsPagedQueryDto>('conducted', query);
  }

  public getLessonsForQuizGeneration(): Observable<Omit<StudentQuizzesDataInterface, 'quizzes'>[]> {
    return this.get<Omit<StudentQuizzesDataInterface, 'quizzes'>[]>('quiz-generation-data');
  }

  public rateLessonById(id: string, body: RateLessonDto) {
    return this.put<void>(`${id}/rate`, body);
  }

  public skipLessonById(id: string, body: SkipLessonBodyDto) {
    return this.post<SkipLessonResponseDto>(`${id}/skip`, body);
  }

  public bookAdditionalLesson(body: BookAdditionalLessonDto) {
    return this.post<boolean>('book-additional', body);
  }
}
