import { Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'math-ai-loader',
  standalone: true,
  imports: [TranslatePipe, NgTemplateOutlet],
  templateUrl: './ai-loader.component.html',
  styleUrl: './ai-loader.component.scss',
})
export class AiLoaderComponent {
  public readonly text = input<string>();
}
